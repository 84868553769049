import { faMemo } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Anchor, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ReactHelmet from "react-helmet-async";
import { Link, generatePath } from "react-router-dom";

import {
  useExpert,
  useExpertDocuments,
  useExpertSoftwareSelection,
} from "@/hooks/use-expert";

import { paths } from "@/routes/paths";

import { TextOverflowWrapper } from "@/components/atoms/TextOverflowWrapper";
import { SoftwareGrid } from "@/components/molecules/SoftwareGrid/SoftwareGrid";
import type { SDocument, User } from "types";
import { Skeleton } from "../../atoms/Loading/Skeleton";
import { UserProfile } from "../../organisms/UserProfile/UserProfile";

const { Helmet } = ReactHelmet;

type Props = {
  identifier: string;
  className?: string;
  onDocumentClick?: (document: SDocument) => void;
};

export function ExpertProfile(props: Props) {
  const expert = useExpert({ identifier: props.identifier });
  const softwares = useExpertSoftwareSelection({
    identifier: expert.data?.urlId || expert.data?.id,
  });
  const documents = useExpertDocuments({
    identifier: expert.data?.urlId || expert.data?.id,
  });

  if (expert.isLoading || softwares.isLoading || documents.isLoading) {
    return (
      <Stack className="mt-6">
        <Skeleton repeat={5} />
      </Stack>
    );
  }

  return (
    <Stack className={props.className}>
      {expert.isError ? (
        <Text className="text-[--mantine-color-gray-6]">
          Failed to load expert information.
        </Text>
      ) : (
        <UserProfile user={expert?.data || {}} showFullProfileOnClick={false} />
      )}
      {expert.data && (
        <>
          <MetaTags expert={expert.data} />
          <Text className="mt-2 font-semibold text-xl/normal">
            Expertise summary
          </Text>
          <TextOverflowWrapper>{expert?.data?.summary}</TextOverflowWrapper>
          <Text className="mt-2 font-semibold text-xl/normal">
            Software expertise
          </Text>
          {softwares?.data &&
            (softwares.data.length > 0 ? (
              <SoftwareGrid softwares={softwares.data} />
            ) : (
              <div className="rounded-lg bg-[--mantine-color-gray-0] p-6">
                <Text className="text-[--mantine-color-gray-6]">
                  This expert hasn't yet provided their areas of software
                  expertise. Please check their LinkedIn profile to learn about
                  their experience.
                </Text>
              </div>
            ))}
          {softwares.isError && (
            <Text className="text-[--mantine-color-gray-6]">
              Failed to load expert's software expertise.
            </Text>
          )}
          <Text className="mt-2 font-semibold text-xl/normal">
            Guides written
          </Text>
          {!!documents?.data?.length && (
            <ul className="m-0 flex list-none flex-col gap-2 p-0">
              {documents?.data?.map((sDocument) => (
                <li key={sDocument.id}>
                  <Anchor
                    component={Link}
                    to={generatePath(paths.documentation.viewer, {
                      urlSlug: sDocument.urlTitle,
                    })}
                    className="flex items-center gap-2 py-2 text-inherit"
                    onClick={() => props.onDocumentClick?.(sDocument)}
                  >
                    {sDocument.docType === "SOFTWARE_CHECKLIST" ? (
                      <>
                        <img
                          src={sDocument.software?.icon}
                          alt={sDocument.software?.name}
                          className="size-5"
                        />
                        <span className="cursor-pointer font-medium underline">
                          {sDocument.software?.name} guide
                        </span>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faMemo}
                          className="size-5 text-[--mantine-primary-color-filled]"
                        />
                        <span className="cursor-pointer font-medium underline">
                          {sDocument.topic?.title}
                        </span>
                      </>
                    )}
                  </Anchor>
                </li>
              ))}
            </ul>
          )}
          {!documents?.data?.length && (
            <div className="rounded-lg bg-[--mantine-color-gray-0] p-6">
              <Text className="text-[--mantine-color-gray-6]">
                This expert hasn't yet created any documents.
              </Text>
            </div>
          )}
          {documents.isError && (
            <Text className="text-[--mantine-color-gray-6]">
              Failed to load expert's documents.
            </Text>
          )}
        </>
      )}
    </Stack>
  );
}

function MetaTags({ expert }: { expert: User }) {
  const path = generatePath(paths.experts.profile, { identifier: expert.id });

  // TODO: When upgrading to >=react-router 6.4, replace with useBasename
  const fullUrl = `${window.location.protocol}//${window.location.host}${path}`;

  return (
    <Helmet prioritizeSeoTags={true}>
      <meta property="og:url" content={fullUrl} />
      <link rel="canonical" href={fullUrl} />
      <title>Guidestack Expert - {expert.name}</title>
      <meta name="description" content={expert.summary} />
    </Helmet>
  );
}
